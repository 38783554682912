import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });
  
  
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    
    const PUBLIC_KEY = "tKjXYzWt0H63q0LTR"
    const SERVICE_ID = "service_mh3jb47"
    const TEMPLATE_ID = "template_uhktaba"

    emailjs
      .sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY)
      .then(
        (result) => {
          console.log(result.text);
          clearState();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div>
      <div id="kontakt">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Napisz do nas!</h2>
                <p>
                  Jeżeli masz jakieś pytania, uzupełnij formularz i wyślij nam wiadomość, odpowiemy jak tylko będziemy mogli!
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Imię"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Wiadomość"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Wyślij wiadomość
                </button>
              </form>
              <p> Nasze boxy dostępne są cały tydzień!</p>
<p>Zamówienia przyjmujemy do godziny 18:00 każdego dnia poprzedzającego zamówienie tylko i wyłącznie pod numerem telefonu znajdującym się po prawej stronie.</p>
                  <p>Przyjmujemy tylko płatność z góry - przelew/blik. Przy płatności przelewem prosimy o wysłanie potwierdzenia na wskazany numer telefonu.</p>
                  <p>Istnieje możliwość przygotowania naszych przekąsek również na ozdobnych deskach - gotowych do postawienia na stół. 
                    Za deskę pobieramy kaucję zwrotną w wysokości 200 zł oraz opłatę w wysokości 20 zł za wypożyczenie deski.
                    Czas na zwrot deski wynosi 7 dni w tym dni weekendowe. </p>
                    <p>Boxy oraz deski można odebrać w naszej restauracji – Restauracja W Szczerym Polu lub
zamówić z dostawą. Dowóz na terenie miasta gratis, po za terenem miasta +2 zł/km.</p>
<p>Pamiętaj! Jesteśmy dla Ciebie- stworzymy twój wymarzony Box.</p>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Informacje kontaktowe</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Adres
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Telefon
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.instagram : "/"}>
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024 Party My Box
          </p>
        </div>
      </div>
    </div>
  );
};
