import React, { useState, useEffect, useRef } from "react";

export const Gallery = (props) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCalling, setIsCalling] = useState(false);

  const modalRef = useRef(null);

  const openModal = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const closeModal = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setSelectedItem(null);
      setIsModalOpen(false);
    } else if (event.target.getAttribute("href") === "#kontakt") {
      setSelectedItem(null);
      setIsModalOpen(false);
    }
  };

  const call = () => {
    setIsCalling(!isCalling)
  }

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Escape") {
        closeModal(event);
      }
    };
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <div id="oferta" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Oferta</h2>
          <p>Kliknij w boxa, aby dowiedzieć się więcej!</p>
        </div>
        <div className="row  no-gutters">
          <div className="portfolio-items">
            {props.data
              ? props.data.map((item, i) => (
                  <div
                    key={`${item.title}-${i}`}
                    className="col-sm-4 col-sm-4 col-sm-4 align-self-center"
                  >
                    <div className="portfolio-item">
                      <div
                        className="hover-bg"
                        onClick={() => openModal(item)}
                      >
                        <div className="hover-text">
                          <h4>{item.title}</h4>
                        </div>
                        <img 
                          src={item.smallImage}
                          alt={item.title}
                        />
                      </div>
                    </div>
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal" ref={modalRef}>
            {selectedItem.largeImage && selectedItem.largeImage.length > 0 && (
              <img
                src={selectedItem.largeImage}
                className="modal-image"
                alt={selectedItem.title}
              />
            )}
            <h1 className="modal-title">{selectedItem.title}</h1>
            {selectedItem.person && (
              <div className="modal-details">
                <p>Liczba osób: {selectedItem.person}</p>
              </div>
            )}
            {
              selectedItem.weight && (
                <div className="modal-details">
                  <p>Waga: {selectedItem.weight}</p>
                </div>
              )
            }
            {selectedItem.price && (
              <div className="modal-details">
                <p>Cena: {selectedItem.price} PLN</p>
              </div>
            )}
            {selectedItem.composition && selectedItem.composition.length > 0 && (
              <div className="modal-details">
                <p className="sklad">Skład:</p>
                <ul>
                  {selectedItem.composition.map((ingredient, index) => (
                    <li key={index}>{ingredient}</li>
                  ))}
                </ul>
              </div>
            )}
            <div onClick={call} className="btn btn-custom btn-lg page-scroll"> Zamów </div> 
            <div className="callingPhone">
               { isCalling ? <a href="tel:726505585">726 505 585</a> : <></>}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
